import type { AppProps } from "next/app";
import { I18nProvider } from "next-localization";
import type { SitecorePageProps } from "lib/page-props";
import { ChakraProvider } from "@chakra-ui/react";

import theme from "themes/index";
import Bootstrap from "src/Bootstrap";

function App({ Component, pageProps }: AppProps<SitecorePageProps>) {
    const { dictionary, ...rest } = pageProps;

    return (
        <>
            <Bootstrap {...pageProps} />
            <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
                <ChakraProvider theme={theme}>
                    <Component {...rest} />
                </ChakraProvider>
            </I18nProvider>
        </>
    );
}

export default App;
