import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import animations from "themes/foundations/animations";
import { pxToRem } from "themes/utils/mapPxToRem";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    accordionAnatomy.keys
);

const baseStyle = definePartsStyle({
    icon: {
        minWidth: pxToRem("24px"),
        minHeight: pxToRem("24px"),
    },
});

const brandYellow = "brand.yellow";
const brandGray = "brand.lightGray";

const variants = {
    ContentAccordion: {
        root: { borderTop: "1px solid", borderColor: brandGray },
        container: {},
        button: {
            textAlign: "left",
            gap: ["sp12", null, null, "sp24"],
            alignItems: ["top", null, null, null, "center"],
            fontSize: ["sm", null, null, "xl", null],
            px: "sp24",
            py: "sp32",
            _hover: { background: brandYellow },
            _focusVisible: { background: brandYellow },
            _expanded: { background: brandYellow },
            transitionDuration: animations.fast,
        },
        panel: {
            background: brandYellow,
            fontSize: ["xxs", null, null, "sm"],
            px: ["sp60", null, null, "sp216"],
            pb: "sp32",
            pr: [null, null, null, "sp96", "sp96"],
        },
        icon: {},
    },
};

export default defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {},
});
